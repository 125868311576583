import React from 'react';

const IconYoutube = () => (
    <svg style={{ margin: "2px 0 0 0" }} xmlns="http://www.w3.org/2000/svg" width="30" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 31 31">
        <title>Youtube</title>
        <path d="m16.102 3.998h.178c1.644.006 9.974.066 12.22.67a4.02 4.02 90 012.83 2.84c.202.76.344 1.766.44 2.804l.02.208.044.52.016.208c.13 1.828.146 3.54.148 3.914v.15c-.002.388-.02 2.216-.164 4.12l-.016.21-.018.208c-.1 1.144-.248 2.28-.47 3.116a4.02 4.02 90 01-2.83 2.84c-2.32.624-11.138.668-12.36.67h-.284c-.618 0-3.174-.012-5.854-.104l-.34-.012-.174-.008-.342-.014-.342-.014c-2.22-.098-4.334-.256-5.308-.52a4.02 4.02 90 01-2.83-2.838c-.222-.834-.37-1.972-.47-3.116l-.016-.21-.016-.208A62 62 90 010 15.36v-.246c.004-.43.02-1.916.128-3.556l.014-.206.006-.104.016-.208.044-.52.02-.208c.096-1.038.238-2.046.44-2.804a4.02 4.02 90 012.83-2.84c.974-.26 3.088-.42 5.308-.52l.34-.014.344-.012.172-.006.342-.014A200 200 90 0115.716 4zm-3.302 6.42v9.636l8.314-4.816z" />
    </svg>



);

export default IconYoutube;
