import React from 'react';

const IconTwitterNew = () => (
    <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="100 80 190 190">
        <title>TwitterNew</title>
        <g>
            <path
                d="M 344.105469 366.273438 L 32.230469 366.273438 C 19.386719 366.273438 8.917969 355.808594 8.917969 342.960938 L 8.917969 31.085938 C 8.917969 18.242188 19.386719 7.773438 32.230469 7.773438 L 344.105469 7.773438 C 356.949219 7.773438 367.417969 18.242188 367.417969 31.085938 L 367.417969 342.960938 C 367.417969 355.808594 356.949219 366.273438 344.105469 366.273438 "
                fillOpacity="1" fillRule="nonzero" fill="transparent"></path>
        </g>
        <g transform="translate(90,80) "> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
            version="1.1" height="196" width="196">
            <path
                d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
            ></path>
        </svg> </g>    </svg>
);

export default IconTwitterNew;
